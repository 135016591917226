export const UPDATE_AUTH = "UPDATE_AUTH";
export const USER_DETAIL = "USER_DETAIL";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";

export const UPLOAD_PIC = "https://admin.acevedoteamapp.com/uploads/avatar/";
export const UPLOAD_VIDEO = "https://admin.acevedoteamapp.com/uploads/video/";
export const UPLOAD_DOCUMENT =
  "https://admin.acevedoteamapp.com/uploads/document/";

export const TIMEZONE = "America/Los_Angeles";

//Dashboard

export const GET_NEWS = "GET_NEWS";
export const GET_ANNOUNCEMENT = "GET_ANNOUNCEMENT";
export const GET_VALUES = "GET_VALUES";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_MISSION = "GET_MISSION";

//followupBoss
export const FOLLOW_UP_BOSS = "FOLLOW_UP_BOSS";

//theme
export const THEME_UPDATE = "THEME_UPDATE";

//training
export const GET_VIDEO = "GET_VIDEO";
export const GET_DOCUMENT = "GET_DOCUMENT";

//contacts
export const MANAGE_CONTACT = "MANAGE_CONTACT";
export const MANAGE_CONTACT_ACTIVE_STATUS = "MANAGE_CONTACT_ACTIVE_STATUS";

//direct chats
export const MANAGE_DIRECT_CHAT = "MANAGE_DIRECT_CHAT";
export const PREPEND_CONVERSATION_ARRAY = "PREPEND_CONVERSATION_ARRAY";
export const UPDATE_CONVERSATION_ARRAY = "UPDATE_CONVERSATION_ARRAY";
export const UPDATE_SELECTED_CONVERSATION = "UPDATE_SELECTED_CONVERSATION";
export const UPDATE_CONVERSATION_INFINITY = "UPDATE_CONVERSATION_INFINITY";
export const DIRECT_CHAT_INFINITY = "DIRECT_CHAT_INFINITY";
export const UPLOAD_FILE_IN_CONVERSATION = "UPLOAD_FILE_IN_CONVERSATION";
export const REMOVE_FILE_FROM_CONVERSATION = "REMOVE_FILE_FROM_CONVERSATION";
export const UPDATE_DIRECT_UNREAD_COUNT = "UPDATE_DIRECT_UNREAD_COUNT";
export const UPDATE_SELECTED_CHAT_ACTIVE_STATUS =
  "UPDATE_SELECTED_CHAT_ACTIVE_STATUS";

//group chats
export const MANAGE_GROUP_CHAT = "MANAGE_GROUP_CHAT";
export const PREPEND_CONVERSATION_ARRAY_GROUP =
  "PREPEND_CONVERSATION_ARRAY_GROUP";
export const UPDATE_CONVERSATION_ARRAY_GROUP =
  "UPDATE_CONVERSATION_ARRAY_GROUP";
export const UPDATE_SELECTED_CONVERSATION_GROUP =
  "UPDATE_SELECTED_CONVERSATION_GROUP";
export const UPDATE_CONVERSATION_GROUP_ARRAY =
  "UPDATE_CONVERSATION_GROUP_ARRAY";
export const UPDATE_GROUP_CONVER = "UPDATE_GROUP_CONVER";
export const UPDATE_GROUP_CONVERSATION = "UPDATE_GROUP_CONVERSATION";
export const UPLOAD_FILE_IN_GROUP = "UPLOAD_FILE_IN_GROUP";
export const REMOVE_FILE_FROM_GROUP = "REMOVE_FILE_FROM_GROUP";
export const UPDATE_GROUP_UNREAD_COUNT = "UPDATE_GROUP_UNREAD_COUNT";

// Chime
export const LEADS_DATA = "LEADS_DATA";
export const LEADS_TASK = "LEADS_TASK";
