import { LEADS_DATA, LEADS_TASK } from "../../config/actionTypes";
import CRM from "../../api/crm";

export const Chime_Auth = (data) => async (dispatch, getState) => {
  try {
    const user = await getState().user;
    if (user.isAuth) {
      const response = await CRM.post("/chime-auth", data);
      if (response.data.success) {
        localStorage.setItem("auth", response.data.data.access_token);
      }
    }
  } catch (err) {
    console.log("chime auth token error");
  }
};

export const getLeadsData = (data) => async (dispatch, getState) => {
  try {
    const user = await getState().user;
    if (user.isAuth) {
      const response = await CRM.post("/chime-leads", data);
      if (response.data.data.code != 200058) {
        console.log("response.data.data._metadata.total------",response.data.data.leads)
        dispatch({
          type: LEADS_DATA,
          payload: response.data.data.leads,
          total: response.data.data._metadata.total
        });
      } else {
        localStorage.removeItem("auth");
      }
    }
  } catch (err) {
    console.log("chime auth token error");
  }
};

export const getLeadsTask = (data) => async (dispatch, getState) => {
  try {
    const user = await getState().user;
    if (user.isAuth) {
      const response = await CRM.post("/chime-tasks-by-lead", data);
      console.log("response-------chime-----",response)

      if (response.data.data.code != 200058) {
        dispatch({
          type: LEADS_TASK,
          payload: response.data.data,
        });
      } else {
        localStorage.removeItem("auth");
      }
    }
  } catch (err) {
    console.log("chime auth token error");
  }
};
