import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Tabs,
  Spin,
  Skeleton,
  Pagination,
  Button,
  Card,
  Modal,
  Form,
  Input,
} from "antd";
import News from "./component/News";
import Announcements from "./component/Announcements";
import {
  getMission,
  getValues,
  loadMoreValueData,
  loadMoreMissionData,
} from "../../redux/actions/dashboardAction";
import moment from "moment";
import ShowMoreText from "react-show-more-text";

const Dashboard = () => {
  const { TabPane } = Tabs;

  const user = useSelector((state) => state.user);
  const { userDetails } = user;
  console.log(userDetails);
  function callback(key) {
    console.log(key);
  }
  const dispatch = useDispatch();
  const dashboard_data = useSelector((state) => state.dashboard_data);
  const { loading, get_Values, get_Mission, get_Value, missionData } =
    dashboard_data;

  useEffect(() => {
    collectData();
  }, []);

  const collectData = async () => {
    dispatch(getMission());
    dispatch(getValues());
  };

  const showTotal = async (total) => {
    return `Total ${total} items`;
  };

  const [current, setCurrent] = React.useState(1);
  let setSize = false;

  const sizeChangeLogic = (current, size) => {
    setSize = true;
    setCurrent(1);
  };

  const onChangeLogic = async (current, size) => {
    !setSize && setCurrent(current);
    console.log(current);
    await dispatch(loadMoreValueData(current));
  };

  const [currentMission, setCurrentMission] = React.useState(1);
  let setSizeMission = false;

  const sizeChangeLogicMission = (current, size) => {
    setSizeMission = true;
    setCurrentMission(1);
  };

  const onChangeLogicMission = async (current, size) => {
    !setSizeMission && setCurrentMission(current);
    console.log(current);
    await dispatch(loadMoreMissionData(current));
  };

  return (
    <div className="content_all">
      <Row gutter={[26, 20]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h3 className="page-title">Dashboard</h3>
        </Col>
        <Col xs={24} sm={24} md={15} lg={15}>
          <div className="left_frm">
            <div className="tabs_dashboard">
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="News " key="1">
                  <div className="">
                    <News />
                  </div>
                </TabPane>
                <TabPane tab="Values & Beliefs" key="2">
                  {loading ? (
                    <Spin className="loader-ld" />
                  ) : (
                    <>
                      {get_Values == "" ? (
                        "No Data Found"
                      ) : (
                        <>
                          {get_Value.map((item) => (
                            <div className="values-beliefs-page">
                              <h2>{item.title}</h2>
                              <p>
                                <ShowMoreText
                                  lines={20}
                                  more="Show more"
                                  less="Show less"
                                  expanded={false}
                                  truncatedEndingComponent={"..."}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.message,
                                    }}
                                  ></div>
                                </ShowMoreText>
                              </p>
                            </div>
                          ))}
                        </>
                      )}
                      {get_Values.next_page_url != null ? (
                        <Pagination
                          pageSize={
                            get_Values && get_Values.per_page != null
                              ? get_Values.per_page
                              : null
                          }
                          size="small"
                          total={
                            get_Values && get_Values.total != null
                              ? get_Values.total
                              : null
                          }
                          showTotal={(total) => `Total ${total} items`}
                          current={current}
                          onChange={onChangeLogic}
                        />
                      ) : null}
                    </>
                  )}
                </TabPane>
                <TabPane tab="Our Mission" key="3">
                  {loading ? (
                    <Spin className="loader-ld" />
                  ) : (
                    <>
                      {get_Mission == "" ? (
                        "No Data Found"
                      ) : (
                        <>
                          {get_Mission.map((item) => (
                            <div className="values-beliefs-page">
                              <h2>{item.title}</h2>
                              <p>
                                <ShowMoreText
                                  lines={3}
                                  more="Show more"
                                  less="Show less"
                                  expanded={false}
                                  truncatedEndingComponent={"..."}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.body,
                                    }}
                                  ></div>
                                </ShowMoreText>
                              </p>
                            </div>
                          ))}
                        </>
                      )}
                      <>
                        {missionData.next_page_url != null ? (
                          <Pagination
                            pageSize={
                              missionData && missionData.per_page != null
                                ? missionData.per_page
                                : null
                            }
                            size="small"
                            total={
                              missionData && missionData.total != null
                                ? missionData.total
                                : null
                            }
                            showTotal={(total) => `Total ${total} items`}
                            current={currentMission}
                            onChange={onChangeLogicMission}
                          />
                        ) : null}
                      </>
                    </>
                  )}
                </TabPane>
              </Tabs>
            </div>
          </div>
        </Col>

        <Col xs={24} sm={24} md={9} lg={9}>
          <div className="left_frm">
            <div className="ekit-heading--title">
              <h2>Announcements</h2>
            </div>
            <Announcements />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Dashboard;
