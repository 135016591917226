import { GET_VIDEO, GET_DOCUMENT } from "../../config/actionTypes";

const INITIAL_STATE = {
  loading: false,
  isPasswordUpdated: false,
  get_Video: [],
  get_Document: [],
  videoLoadMoreURL: null,
  documentLoadMoreURL: null,
};

const training = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_VIDEO:
      const get_Video = { ...state, ...action.payload };
      return get_Video;
    case GET_DOCUMENT:
      const get_Document = { ...state, ...action.payload };
      return get_Document;

    default:
      return state;
  }
};

export default training;
