import React, { useEffect, useState } from "react";

import { Row, Col, Spin, Modal, Button } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import { ReloadOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { TIMEZONE, UPLOAD_DOCUMENT } from "../../../config/actionTypes";
import moment from "moment-timezone";
import ShowMoreText from "react-show-more-text";
import {
  getDocument,
  loadMoreDocument,
} from "../../../redux/actions/trainingAction";
import { DocumentViewer } from "react-documents";

const Documents = () => {
  const dispatch = useDispatch();
  const training = useSelector((state) => state.training);
  const { get_Document, loading, documentLoadMoreURL } = training;
  const [docName, setDocName] = useState("");
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    collectDocument();
  }, []);

  const collectDocument = async () => {
    await dispatch(getDocument());
  };

  const onClickDocument = (doc_url) => {
    setDocName(doc_url);
  };

  useEffect(() => {
    if (!visible && docName !== "") setVisible(true);
  }, [docName]);

  const downloadFile = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="video-letter">
      {loading ? (
        <Spin className="loader-ld" />
      ) : (
        <>
          {get_Document === "" ? (
            "No Data Found"
          ) : (
            <Row gutter={[16, 0]}>
              {get_Document.map((item) => {
                return (
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Row gutter={[0, 0]}>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        style={{
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="video-img"
                        >
                          {/* <FilePdfOutlined
                            style={{
                              fontSize: "64px",
                              color: "#08c",
                              width: "100%",
                              padding: "5px",
                            }}
                          /> */}
                          <DocumentViewer
                            url={UPLOAD_DOCUMENT + "/" + item.doc_name}
                            onClick={() =>
                              downloadFile(UPLOAD_DOCUMENT + "/" + item.doc_name)
                            }

                            viewer="google"
                          >
                          </DocumentViewer>
                          {/* <img src={black} alt="" /> */}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <div className="video-para">
                          <h3>{item.name}</h3>
                          <p>
                            <ShowMoreText
                              lines={3}
                              more="Show more"
                              less="Show less"
                              expanded={false}
                              truncatedEndingComponent={"..."}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              ></div>
                            </ShowMoreText>
                          </p>
                          <span className="time-vo">
                            {moment
                              .tz(item.created_at, TIMEZONE)
                              .format("LLLL")}
                            {/* <br></br>
                            <i className="fa fa-clock-o" aria-hidden="true"></i>
                            {moment.tz(item.created_at, TIMEZONE).format("LT")} */}
                          </span>
                          <br />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
              <Modal
                title="View"
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={1000}
                afterClose={() => setDocName("")}
              >
                <DocumentViewer
                  style={{ width: "100%", height: 600 }}
                  queryParams="hl=Nl"
                  url={UPLOAD_DOCUMENT + "/" + docName}
                  overrideLocalhost="https://react-doc-viewer.firebaseapp.com/"
                />
              </Modal>
            </Row>
          )}

          {documentLoadMoreURL != null ? (
            <Col xs={24} sm={24} md={24} lg={24}>
              <div
                className="Loadmore-btn-lb"
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() =>
                    dispatch(loadMoreDocument(documentLoadMoreURL))
                  }
                >
                  <ReloadOutlined /> Load More
                </Button>
              </div>
            </Col>
          ) : null}
          {/* {get_News && get_News.next_page_url != null ? (
     <Pagination
       pageSize={
         get_News && get_News.per_page != null ? get_News.per_page : null
       }
       size="small"
       total={get_News && get_News.total != null ? get_News.total : null}
       showTotal={(total) => `Total ${total} items`}
       current={currentNews}
       onChange={onChangeLogicNews}
     />
   ) : null} */}
        </>
      )}
    </div>
  );
};
export default Documents;
