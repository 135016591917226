import React, { useEffect } from "react";

import { Card, Button, Input, Row, Col, Form, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updatedPassword } from "../../redux/actions/updatePasswordAction";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

function UpdatePassword() {
  const [form] = Form.useForm();
  const isPasswordUpdated = useSelector(
    (state) => state.updatePassword.isPasswordUpdated
  );

  const user = useSelector((state) => state.user);
  const { loading, userDetails } = user;

  const onFinish = async (values) => {
    const newPassword = await {
      ...values,
    };
    dispatch(updatedPassword(newPassword));
  };

  useEffect(() => {
    if (isPasswordUpdated) {
      form.resetFields();
    }
  }, [isPasswordUpdated]);

  const dispatch = useDispatch();

  return (
    <div>
      {loading ? (
        <Spin className="loader-ld" />
      ) : (
        <Row gutter={[26, 20]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div className="left_frm">
              <div className="form-element-br">
                <Form
                  name="basic"
                  form={form}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Row gutter={[0, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item
                        name="old_password"
                        label="Current Password"
                        required={true}
                        rules={[
                          {
                            required: false,
                            message: "Please enter your current password!",
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder="Enter your Current password"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item
                        name="new_password"
                        label="New Password"
                        required={true}
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                          {
                            min: 8,
                            message: "Password must be minimum 8 characters.",
                          },
                          {
                            pattern: "^[a-zA-Z0-9_]*$",
                            message:
                              "Please input only letters or numbers. Thank you!",
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder="Enter your password"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item
                        name="c_password"
                        label="Confirm Password"
                        required={true}
                        rules={[
                          {
                            required: true,
                            message: "Please input your Confirm Password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("new_password") === value
                              ) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error("Password does not match!")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          placeholder="Enter Confirm password"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item wrapperCol={{ offset: 7, span: 14 }}>
                        <div className="submitbtn">
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}
export default UpdatePassword;
