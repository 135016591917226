import React from "react";

import { Layout, Menu, Dropdown, Badge, Form, Input, Button } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
  InboxOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
const Notification = () => {
  const menu = (
    <Menu className="notification-downmenu0">
      <Menu.Item key="0">
        <div className="flexbox-nv">
          <div>
            <h4>Notifications</h4>
          </div>
          <div>
            <p> Clear All</p>
          </div>
        </div>
      </Menu.Item>
      <Menu.Item key="1">
        <div className="menu-sm-scrol">
          <p>
            <Link to="">
              <i className="fa fa-users text-info"></i> Curabitur id eros quis
              nunc suscipit blandit.
            </Link>
          </p>
          <p>
            <Link to="">
              <i className="fa fa-users text-info"></i> Curabitur id eros quis
              nunc suscipit blandit.
            </Link>
          </p>
          <p>
            <Link to="">
              <i className="fa fa-users text-info"></i> Curabitur id eros quis
              nunc suscipit blandit.
            </Link>
          </p>
        </div>
      </Menu.Item>
    </Menu>
  );


  return (
    <div className="notifications-menu-nv">
      <Badge size="small" count={null} offset={[5, -5]}>
        <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <i className="fa fa-bell" aria-hidden="true"></i>
          </a>
        </Dropdown>
      </Badge>

    </div>
  );
};
export default Notification;
