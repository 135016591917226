import { FOLLOW_UP_BOSS } from "../../config/actionTypes";

const INITIAL_STATE = {
  loading: false,
  identity: {},
};

const FollowUpBoss = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FOLLOW_UP_BOSS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default FollowUpBoss;
