import {
  GET_NEWS,
  GET_ANNOUNCEMENT,
  GET_VALUES,
  GET_DASHBOARD,
  GET_MISSION,
} from "../../config/actionTypes";
import CRM from "../../api/crm";
import { message } from "antd";

export const getAnnouncement = (data) => async (dispatch, getState) => {
  try {
    const user = await getState().user;

    const response = await CRM.get("/get-announcements", data);
    if (response.data.success) {
      return dispatch({
        type: GET_ANNOUNCEMENT,
        payload: {
          loading: false,
          get_Announcement: response.data.data.data,
          announcement_data: response.data.data,
          getAnnouncementLoadMore: response.data.data.next_page_url,
          successMessage: response.data.message,
        },
      });
    } else {
      return dispatch({
        type: GET_ANNOUNCEMENT,
        payload: {
          loading: false,
          get_Announcement: [],
          errorMessage: "Error: ",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ANNOUNCEMENT,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const loadMoreAnnouncementData =
  (number) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ANNOUNCEMENT,
        payload: {
          loading: true,
        },
      });

      const response = await CRM.get(
        "https://crmadmin.arksstech.com/api/get-announcements?page=" + number
      );
      console.log(response);
      if (response.data.success) {
        const newGetAnnouncement = response.data.data.data;

        dispatch({
          type: GET_ANNOUNCEMENT,
          payload: {
            loading: false,

            get_Announcement: newGetAnnouncement,

            getAnnouncementLoadMore: response.data.data.next_page_url,
          },
        });
      } else {
        dispatch({
          type: GET_ANNOUNCEMENT,
          payload: {
            loading: false,
            errorMessage: "Error: " + response.data.message,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ANNOUNCEMENT,
        payload: {
          loading: false,
          errorMessage: "Error: " + err,
        },
      });
    }
  };

export const getNews = (data) => async (dispatch, getState) => {
  try {
    const user = await getState().user;

    const response = await CRM.get("/get-news", data);
    if (response.data.success) {
      return dispatch({
        type: GET_NEWS,
        payload: {
          loading: false,
          get_News: response.data.data,
          News_data: response.data.data.data,
          getNewsLoadMore: response.data.data.next_page_url,
          successMessage: response.data.message,
        },
      });
    } else {
      return dispatch({
        type: GET_NEWS,
        payload: {
          loading: false,
          News_data: [],
          get_News: {},
          errorMessage: "Error: ",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_NEWS,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const loadMoreNewsData = (number) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_NEWS,
      payload: {
        loading: true,
      },
    });

    console.log(number);
    const response = await CRM.get(
      "https://crmadmin.arksstech.com/api/get-news?page=" + number
    );
    if (response.data.success) {
      const newGetNews = response.data.data.data;

      dispatch({
        type: GET_NEWS,
        payload: {
          loading: false,

          News_data: newGetNews,

          getNewsLoadMore: response.data.data.next_page_url,
        },
      });
    } else {
      dispatch({
        type: GET_NEWS,
        payload: {
          loading: false,
          errorMessage: "Error: " + response.data.message,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_NEWS,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const getValues = (data) => async (dispatch, getState) => {
  try {
    const user = await getState().user;

    const response = await CRM.get("/get-value", data);
    if (response.data.success) {
      return dispatch({
        type: GET_VALUES,
        payload: {
          loading: false,
          get_Values: response.data.data,
          get_Value: response.data.data.data,
          successMessage: response.data.message,
          getValuesLoadMore: response.data.data.next_page_url,
        },
      });
    } else {
      return dispatch({
        type: GET_VALUES,
        payload: {
          loading: false,
          get_Value: [],
          get_Values: {},
          errorMessage: "Error: ",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VALUES,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const loadMoreValueData = (number) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_VALUES,
      payload: {
        loading: true,
      },
    });

    const response = await CRM.get(
      "https://crmadmin.arksstech.com/api/get-value?page=" + number
    );
    console.log(response);
    if (response.data.success) {
      const newGetValues = response.data.data.data;

      dispatch({
        type: GET_VALUES,
        payload: {
          loading: false,

          get_Value: newGetValues,

          getValuesLoadMore: response.data.data.next_page_url,
        },
      });
    } else {
      dispatch({
        type: GET_VALUES,
        payload: {
          loading: false,
          errorMessage: "Error: " + response.data.message,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VALUES,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const getMission = (data) => async (dispatch, getState) => {
  try {
    const user = await getState().user;

    const response = await CRM.get("/get-mission", data);
    if (response.data.success) {
      return dispatch({
        type: GET_MISSION,
        payload: {
          loading: false,
          get_Mission: response.data.data.data,
          missionData: response.data.data,
          getValuesLoadMore: response.data.data.next_page_url,
          successMessage: response.data.message,
        },
      });
    } else {
      return dispatch({
        type: GET_MISSION,
        payload: {
          loading: false,
          get_Mission: [],
          missionData: {},
          errorMessage: "Error: ",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_MISSION,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const loadMoreMissionData = (number) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_MISSION,
      payload: {
        loading: true,
      },
    });

    const response = await CRM.get(
      "https://crmadmin.arksstech.com/api/get-mission?page=" + number
    );

    if (response.data.success) {
      const newGetMission = response.data.data.data;

      dispatch({
        type: GET_MISSION,
        payload: {
          loading: false,

          get_Mission: newGetMission,

          getMissionLoadMore: response.data.data.next_page_url,
        },
      });
    } else {
      dispatch({
        type: GET_MISSION,
        payload: {
          loading: false,
          errorMessage: "Error: " + response.data.message,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_MISSION,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};
