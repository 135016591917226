import React, { useEffect } from "react";
import { Form, Input, Button, Row, Col, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "../../style/login.css";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../redux/actions/userAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const ResetPassword = () => {
  const { token, email } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const user = useSelector((state) => state.user);
  const { isAuth, loading } = user;

  useEffect(() => {
    showEmail();
  }, []);
  const showEmail = async () => {
    const formObj = {
      email: email,
    };
    await form.setFieldsValue(formObj);
  };

  const onFinish = async (values) => {
    const newPassword = await {
      ...values,
      email: email,
      token: token,
    };
    dispatch(resetPassword(newPassword));
    navigate("/login");
    form.resetFields();
  };

  return (
    <div className="login_contnainer">
      <Row
        gutter={[6, 6]}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col xs={24} sm={24} md={10} lg={10}>
          <div className="login-form">
            <Row gutter={[6, 6]}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <div className="content-top-agile">
                  <h2 className=" eds-text-hl">Forget Password</h2>
                  <p>Enter your registered email to reset your password.</p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form
                  name="normal_login"
                  className="frm-Login"
                  form={form}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                >
                  <Row gutter={[5, 5]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Email address!",
                          },
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                        ]}
                      >
                        <Input
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          placeholder="Email address"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item
                        name="password"
                        required={true}
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                          {
                            min: 8,
                            message: "Password must be minimum 8 characters.",
                          },
                          {
                            pattern: "^[a-zA-Z0-9_]*$",
                            message:
                              "Please input only letters or numbers. Thank you!",
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder="Enter your password"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item
                        name="password_confirmation"
                        required={true}
                        rules={[
                          {
                            required: true,
                            message: "Please input your Confirm Password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error("Password does not match!")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          placeholder="Enter Confirm password"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item>
                        <div className="login-form-button">
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ResetPassword;
