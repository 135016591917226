import {
  UPDATE_AUTH,
  USER_DETAIL,
  THEME_UPDATE,
} from "../../config/actionTypes";

const INITIAL_STATE = {
  loading: false,
  isAuth: false,
  userDetails: {},
  themeChange: false,
};

const User = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH:
      return { ...state, ...action.payload };
    case USER_DETAIL:
      return { ...state, ...action.payload };

    case THEME_UPDATE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default User;
