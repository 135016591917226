import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  Upload,
  Card,
  Skeleton,
  Spin,
  Tabs,
} from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import UpdatePassword from "./UpdatePassword";
import {
  userDetailUpdate,
  userDetailData,
  themeChange,
  removeProfilePicture,
} from "../../redux/actions/userAction";
import pic from "../../img/pic.jpg";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { AVATAR_URL } from "../../config/constants";
import DefaultAvatar from "../../components/DefaultAvatar";

const Profile = () => {
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [videoUrl, setURL] = useState("");
  const [videoType, setType] = useState("");
  const onFinish = async (values) => {
    const newData = {
      ...values,
      avatar: videoUrl,
      type: videoType,
    };
    await dispatch(userDetailUpdate(newData));
    dispatch(userDetailData());
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { loading, userDetails, themeChange } = user;
  console.log(themeChange);
  useEffect(() => {
    collectSocailmediaData();
    setFileList(
      userDetails &&
        userDetails.user_details &&
        userDetails.user_details.avatar != null
        ? [
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url:
                userDetails &&
                userDetails.user_details &&
                userDetails.user_details.avatar != null
                  ? AVATAR_URL + userDetails.user_details.avatar
                  : null,
            },
          ]
        : []
    );
  }, [userDetails]);

  /* useEffect(() => {

    dispatch(userDetailData());
  }, [userDetails]); */

  const collectSocailmediaData = async () => {
    if (Object.keys(userDetails).length !== 0) {
      const formObj = {
        fname:
          userDetails && userDetails.user && userDetails.user.fname != ""
            ? userDetails.user.fname
            : null,
        lname:
          userDetails && userDetails.user && userDetails.user.lname != ""
            ? userDetails.user.lname
            : null,
        email:
          userDetails && userDetails.user && userDetails.user.email != ""
            ? userDetails.user.email
            : null,
        phone:
          userDetails &&
          userDetails.user_details &&
          userDetails.user_details.phone != ""
            ? userDetails.user_details.phone
            : null,
        /*  phone: userDetails.user_details.phone, */
      };
      console.log(formObj);
      await form.setFieldsValue(formObj);
    }
  };

  const [fileList, setFileList] = useState(
    userDetails &&
      userDetails.user_details &&
      userDetails.user_details.avatar != null
      ? [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url:
              userDetails &&
              userDetails.user_details &&
              userDetails.user_details.avatar != null
                ? AVATAR_URL + userDetails.user_details.avatar
                : null,
          },
        ]
      : []
  );

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onProfileRemove = async () => {
    await dispatch(removeProfilePicture());
    dispatch(userDetailData());
    setType("");
    setURL("");
    //setFileList([]);
  };

  const onChange = async ({ fileList: newFileList }) => {
    if (fileList && fileList[0] && fileList[0].thumbUrl && fileList[0].type) {
      const pic = fileList[0].thumbUrl;

      const splitted = pic.split(",");
      const updatedType = "data:" + fileList[0].type + ";base64";
      const updatedURL = splitted[splitted.length - 1];

      setType(updatedType);
      setURL(updatedURL);
    }

    setFileList(newFileList);
  };

  useEffect(() => {
    if (fileList.length) {
      fileList[0].status = "done";
    }
  }, [fileList]);

  function onChangeMode(checked) {
    console.log(`switch to ${checked}`);
  }
  function callback(key) {
    console.log(key);
  }

  const [isDarkMode, setIsDarkMode] = useState(false);
  const { switcher, themes } = useThemeSwitcher();
  const switchTheme = async (isDarkMode) => {
    setIsDarkMode(isDarkMode);
    switcher({ theme: isDarkMode ? themes.dark : themes.light });
    console.log(isDarkMode);
    await localStorage.setItem("theme", isDarkMode);
    /*  dispatch(themeChange(isDarkMode)); */
  };
  useEffect(() => {
    themeUpdate();
  }, []);

  const themeUpdate = async () => {
    const themeData = await localStorage.getItem("theme");
    if (themeData === "true") {
      setIsDarkMode(true);
    }
  };

  /* const [isDarkMode, setIsDarkMode] = React.useState();
  const { switcher, currentTheme, status, themes } = useThemeSwitcher();

  const toggleTheme = (isChecked) => {
    setIsDarkMode(isChecked);
    switcher({ theme: isChecked ? themes.dark : themes.light });
  };


  if (status === loading) {
    return null;
  } */

  return (
    <div className="content_all">
      {loading ? (
        <Spin className="loader-ld" />
      ) : (
        <Row gutter={[26, 20]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <h3 className="page-title">Profile</h3>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18}>
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="Update Profile" key="1">
                <div className="left_frm">
                  <div className="form-element-br">
                    <Form
                      name="basic"
                      form={form}
                      initialValues={{ remember: true }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <Row gutter={[0, 0]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Form.Item
                            label="First Name"
                            name="fname"
                            rules={[
                              {
                                required: true,
                                message: "Please input first name!",
                              },
                              {
                                max: 20,
                                message:
                                  "First Name must be maximum 20 digits.",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Form.Item
                            label="Last Name"
                            name="lname"
                            rules={[
                              {
                                required: true,
                                message: "Please input last name!",
                              },
                              {
                                max: 20,
                                message: "Last Name must be maximum 20 digits.",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                              {
                                type: "email",
                                message: "The input is not valid E-mail!",
                              },
                            ]}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Form.Item
                            label="Phone"
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Phone!",
                              },
                              {
                                min: 10,
                                message:
                                  "Phone number must be minimum 10 digits.",
                              },
                              {
                                max: 12,
                                message:
                                  "Phone number must be maximum 12 digits.",
                              },
                              {
                                pattern: "^[0-9]+$",
                                message: "Please input a valid phone number",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Form.Item
                            label="Choose image"
                            name="Chooseimage"
                            /*  rules={[
                          {
                            required: true,
                            message: "Please input your Choose image!",
                          },
                        ]} */
                          >
                            <Upload
                              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              listType="picture-card"
                              fileList={fileList}
                              onChange={onChange}
                              onRemove={onProfileRemove}
                              showUploadList={{ showPreviewIcon: false }}
                            >
                              {fileList.length < 1 && "+ Upload"}
                            </Upload>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Form.Item wrapperCol={{ offset: 7, span: 14 }}>
                            <div className="submitbtn">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                  <div className="form-element-br">
                    <div className="setting_pl">
                      <h3>Theme Settings</h3>
                    </div>
                    <div className="toggilebtn">
                      <p>Dark Theme </p>
                      <Switch
                        checkedChildren={"Dark Theme"}
                        unCheckedChildren={"Light Theme"}
                        checked={isDarkMode}
                        onChange={switchTheme}
                      />
                      {/* <Switch checked={isDarkMode} onChange={toggleTheme} /> */}

                      {/* <p>Dark Theme Off</p>
                      <Switch defaultChecked onChange={onChangeMode} /> */}
                    </div>
                    <div className="toggilebtn">
                      <p>Push Notification</p>
                      <Switch defaultChecked /* onChange={onChange} */ />
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Update Password" key="2">
                <UpdatePassword />
              </TabPane>
            </Tabs>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <div className="avatar-bordered_rt">
              {loading ? (
                <Skeleton.Image />
              ) : (
                <>
                  {userDetails &&
                  userDetails.user_details &&
                  userDetails.user_details.avatar != null ? (
                    <img
                      src={AVATAR_URL + userDetails.user_details.avatar}
                      alt="profile"
                    />
                  ) : (
                    <div>
                      <DefaultAvatar
                        fname={
                          userDetails &&
                          userDetails.user &&
                          userDetails.user.fname != ""
                            ? userDetails.user.fname
                            : null
                        }
                        lname={
                          userDetails &&
                          userDetails.user &&
                          userDetails.user.fname != ""
                            ? userDetails.user.lname
                            : null
                        }
                      />
                    </div>
                  )}
                </>
              )}
              <div className="avtart_top1">
                <h4>
                  <span>
                    {userDetails &&
                    userDetails.user &&
                    userDetails.user.fname != ""
                      ? userDetails.user.fname
                      : null}
                  </span>
                  <span> </span>
                  <span>
                    {userDetails &&
                    userDetails.user &&
                    userDetails.user.lname != ""
                      ? userDetails.user.lname
                      : null}
                  </span>
                </h4>
                {/* <span>
                  <i className="fa fa-map-marker w-20"></i> Rancho Cucamonga
                </span> */}
              </div>
            </div>
            <div className="box-profile_rt">
              <p>
                Email
                <span> </span>
                {loading ? (
                  <Skeleton.Input style={{ width: 120 }} />
                ) : (
                  <span className="text-gray pl-10">
                    {userDetails &&
                    userDetails.user &&
                    userDetails.user.email != ""
                      ? userDetails.user.email
                      : "N/A"}
                  </span>
                )}
              </p>
              {userDetails &&
              userDetails.user_details &&
              userDetails.user_details.phone != "" ? (
                <p>
                  Phone
                  <span> </span>
                  <span className="text-gray pl-10">
                    {userDetails &&
                      userDetails.user_details &&
                      userDetails.user_details.phone}
                  </span>
                </p>
              ) : null}

              {/*  <p>
                Address :
                <span className="text-gray pl-10">
                  8338 Day Creek Blvd Suite 102, Rancho Cucamonga, CA 91739
                </span>
              </p> */}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default Profile;
