import { UPDATE_PASSWORD } from "../../config/actionTypes";

const INITIAL_STATE = {
  loading: false,
  isPasswordUpdated: false,
};

const updatePassword = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD:
      const updatedPassword = { ...state, ...action.payload };
      return updatedPassword;

    default:
      return state;
  }
};

export default updatePassword;
