import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

import { useSelector } from "react-redux";

function RequireAuth() {
  const user = useSelector((state) => state.user);
  const { isAuth } = user;
  let location = useLocation();

  if (!isAuth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}

export default RequireAuth;
