import { Avatar } from "antd";
import React from "react";

const DefaultAvatar = ({ fname, lname }) => {
  fname = fname || "";
  lname = lname || "";

  function toTitleCase(str) {
    return str
      .split(/\s+/)
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
      .join(" ");
  }

  var intials = toTitleCase(fname).charAt(0) + toTitleCase(lname).charAt(0);

  return <Avatar size={40}>{intials}</Avatar>;
};

export default DefaultAvatar;
