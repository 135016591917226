import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsData } from "../../redux/actions/ChimeAction";
import { Spin, Table, Select, Pagination } from "antd";
import { CHIME_CLIENT_ID } from "../../config/constants";
import { TIMEZONE } from "../../config/actionTypes";

const Leads = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(0);
  const dispatch = useDispatch();
  const chime_token = localStorage.getItem("auth");
  const { Option } = Select;

  useEffect(() => {
    if(filter==1){
      setPagenation(true)
    }else{
      setPagenation(false)
    }

    const data = {
      access_token: chime_token,
      all: filter,
    };
    dispatch(getLeadsData(data));
  }, [chime_token, filter]);

  const [lead, setleads] = useState([]);
  const [pagenation, setPagenation] = useState(false);
  const { load, leads ,total } = useSelector((state) => state.leads);

  const day = (time) => {
    let date = time.split("GMT");
    return moment.tz(date[0], TIMEZONE).format("lll");
  };

  useEffect(() => {
    if (leads !== undefined) {
      var obj1 = leads.map((data) => {
        const obj = {
          Name: data.firstName + " " + data.lastName,
          Emails: data && data.emails && data.emails[0] ? data.emails[0] : "",
          Phones: data && data.phones && data.phones[0] ? data.phones[0] : "",
          Assigned: data.assignedUser,
          CreateTime: day(data.createTime),
          Details: <a href={"/calendardata?id=" + data.leadId}>View</a>,
        };
        return obj;
      });
      setleads(obj1);
    }
  }, [leads, loading]);

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Emails",
      dataIndex: "Emails",
      key: "Emails",
    },
    {
      title: "Phones",
      dataIndex: "Phones",
      key: "Phones",
    },
    {
      title: "Assigned",
      key: "Assigned",
      dataIndex: "Assigned",
    },
    {
      title: "Created at",
      key: "CreateTime",
      dataIndex: "CreateTime",
      render: (text, record) => {
        return <p>{record.CreateTime}</p>;
      },
    },
    {
      title: "Details",
      key: "Details",
      dataIndex: "Details",
    },
  ];

  setTimeout(() => {
    setLoading(false);
  }, 1800);

  const menu = (
    <Select
      placeholder="Select leads"
      style={{ width: 120 }}
      onChange={setFilter}
      defaultValue={0}
    >
      <Option value={0}>My Leads</Option>
      <Option value={1}>All leads</Option>
    </Select> 
  );

  const paginateLeads = (datas) => {
    setCurrentPage(datas);
  };

  useEffect(() => {
    if (currentPage > 1) {
      const data = {
        access_token: chime_token,
        offset: currentPage,
        all: filter,
      };
  
      dispatch(getLeadsData(data));
    }
  }, [currentPage]);

  return (
    <>
      {loading ? (
        <Spin className="loader-ld" />
      ) : Boolean(chime_token) ? (
        !Boolean(leads) ? (
          <Spin className="loader-ld" />
        ) : (
          <div className="m-5">
            <div className="mb-3">{menu}</div>
            <Table
              scroll={{ x: 400 }}
              columns={columns}
              dataSource={lead}
              pagination={false}
            />
            {
              pagenation ? <Pagination
              defaultCurrent={currentPage}
              pageSize={10}
              current={currentPage}
              total={total}
              onChange={paginateLeads}
              showSizeChanger={false}
            /> : null
            }
            
          </div>
        )
      ) : (
        <a
          className="ant-btn ant-btn-primaryant-btn ant-btn-primary m-5"
          style={{ width: "12rem" }}
          href={`https://chime.me/page/vendor-auth.html?clientId=${CHIME_CLIENT_ID}`}
        >
          authenticate with chime
        </a>
      )}
    </>
  );
};

export default Leads;
