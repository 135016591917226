import { UPDATE_PASSWORD } from "../../config/actionTypes";
import CRM from "../../api/crm";
import { message } from "antd";

export const updatedPassword = (data) => async (dispatch, getState) => {
  try {
    const user = await getState().user;
    if (user.isAuth) {
      dispatch({
        type: UPDATE_PASSWORD,
        payload: {
          loading: true,
          isPasswordUpdated: false,
        },
      });
      const response = await CRM.post("/update-password", data);
      if (response.data.success) {
        message.success(response.data.message);
        return dispatch({
          type: UPDATE_PASSWORD,
          payload: {
            loading: false,
            isPasswordUpdated: true,
            successMessage: response.data.message,
          },
        });
      } else {
        message.error(response.data.message);
        dispatch({
          type: UPDATE_PASSWORD,
          payload: {
            loading: false,
            isPasswordUpdated: false,
            errorMessage: "Error: " + response.data.message,
          },
        });
      }
    } else {
      return dispatch({
        type: UPDATE_PASSWORD,
        payload: {
          loading: false,
          isPasswordUpdated: false,
          errorMessage: "Please login to update password!",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: UPDATE_PASSWORD,
      payload: {
        loading: false,
        isPasswordUpdated: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};
