import React, { useEffect } from "react";
import { Form, Input, Tooltip, Button, Row, Col, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "../../style/login.css";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/actions/userAction";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";

const Login = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { state } = useLocation();
  const user = useSelector((state) => state.user);
  const { isAuth, loading } = user;

  useEffect(() => {
    if (isAuth) {
      state ? navigate(state.from.pathname) : navigate("/dashboard");
    }
  }, [isAuth]);

  const onFinish = (values) => {
    dispatch(login(values));
  };

  return (
    <div className="login_contnainer">
      <div className="headerlogin-hl">
        <Link to="/dashboard">
          <AiOutlineHome />
          <h1>Dashboard</h1>
        </Link>
      </div>

      <Row
        gutter={[6, 6]}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col xs={24} sm={24} md={10} lg={10}>
          <div className="login-form">
            <Row gutter={[6, 6]}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <div className="content-top-agile">
                  <h2 className=" eds-text-hl">The Acevedo Team - Portal</h2>
                  <p>Sign in to start your session</p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form
                  name="normal_login"
                  className="frm-Login"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                >
                  <Row gutter={[5, 5]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item
                        name="user_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Username!",
                          },
                        ]}
                      >
                        <Input
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          placeholder="Username"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                        ]}
                      >
                        {/*   <Tooltip
                          className="tooltipassword0"
                          placement="leftTop"
                          title="Enter a combination of at least six numbers, letters and punctuation marks (such as ! and &)."
                        > */}
                        <Input
                          prefix={
                            <LockOutlined className="site-form-item-icon" />
                          }
                          type="password"
                          placeholder="Password"
                        />
                        {/*   </Tooltip> */}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Row
                        gutter={[0, 0]}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Form.Item
                            name="remember"
                            valuePropName="checked"
                            noStyle
                          >
                            <div className="basic_checkbox_0">
                              <Checkbox>Remember me</Checkbox>
                            </div>
                          </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Form.Item noStyle>
                            <Link
                              to="/forget-password"
                              className="login-form-forgot"
                            >
                              Forgot password
                            </Link>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item>
                        <div className="login-form-button">
                          <Button type="primary" htmlType="submit">
                            Log in
                          </Button>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Login;
