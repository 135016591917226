import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { Chime_Auth } from "../../redux/actions/ChimeAction";

const ChimeAuth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth_code = window.location.href.split("=");
  const chime_token = localStorage.getItem("auth");

  const data = {
    code: auth_code[auth_code.length - 1],
  };

  useEffect(() => {
    authUserWithChime();
  }, []);

  const authUserWithChime = async () => {
    await dispatch(Chime_Auth(data));
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isLoading) {
      navigate("/leads");
    }
  }, [isLoading, chime_token]);

  return <>{isLoading ? <Spin className="loader-ld" /> : null}</>;
};
export default ChimeAuth;
