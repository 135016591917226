import { combineReducers } from "redux";
import dashboard from "./dashboard";
import User from "./user";
import UpdatePassword from "./updatePassword";
import FollowUpBoss from "./followUpBoss";
import Training from "./training";
import DirectChat from "./directChatReducer";
import GroupChat from "./groupChatReducer";
import Contact from "./contactReducer";
import Leads from "./LeadsReducer";

const rootReducer = combineReducers({
  user: User,
  dashboard_data: dashboard,
  updatePassword: UpdatePassword,
  followUpBoss: FollowUpBoss,
  training: Training,
  directChat: DirectChat,
  groupChat: GroupChat,
  contacts: Contact,
  leads: Leads
});

export default rootReducer;
