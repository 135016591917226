import { GET_VIDEO, GET_DOCUMENT } from "../../config/actionTypes";
import CRM from "../../api/crm";
import { message } from "antd";

export const getVideo = (data) => async (dispatch, getState) => {
  try {
    const user = await getState().user;
    if (user.isAuth) {
      dispatch({
        type: GET_VIDEO,
        payload: {
          loading: true,
        },
      });
      const response = await CRM.post("/get-video", data);
      if (response.data.success) {
        return dispatch({
          type: GET_VIDEO,
          payload: {
            loading: false,
            get_Video: response.data.data.data,
            /*  announcement_data: response.data.data, */
            videoLoadMoreURL: response.data.data.next_page_url,
            successMessage: response.data.message,
          },
        });
      }
    } else {
      return dispatch({
        type: GET_VIDEO,
        payload: {
          loading: false,
          get_Video: [],
          errorMessage: "Error: ",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VIDEO,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const loadMoreVideo = (url) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_VIDEO,
      payload: {
        loading: true,
      },
    });
    const endPoint = url.split("/");
    const response = await CRM.post(endPoint[endPoint.length - 1]);
    if (response.data.success) {
      const oldVideo = getState().training.get_Video;
      const newVideo = oldVideo.concat(response.data.data.data);
      dispatch({
        type: GET_VIDEO,
        payload: {
          loading: false,
          get_Video: newVideo,
          videoLoadMoreURL: response.data.data.next_page_url,
        },
      });
    } else {
      dispatch({
        type: GET_VIDEO,
        payload: {
          loading: false,
          errorMessage: "Error: " + response.data.message,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VIDEO,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const searchVideo = (query) => async (dispatch) => {
  try {
    dispatch({
      type: GET_VIDEO,
      payload: {
        loading: true,
      },
    });
    const response = await CRM.post("/get-video", {
      query: query,
    });
    if (response.data.success) {
      dispatch({
        type: GET_VIDEO,
        payload: {
          loading: false,

          get_Video: response.data.data.data,
          //videoLoadMoreURL: null,
          successMessage: response.data.message,
          errorMessage: "",
        },
      });
    } else {
      dispatch({
        type: GET_VIDEO,
        payload: {
          loading: false,
          get_Video: [],
          errorMessage: response.data.message,
          successMessage: "",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VIDEO,
      payload: {
        loading: false,
        get_Video: [],
        successMessage: "",
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const getDocument = (data) => async (dispatch, getState) => {
  try {
    const user = await getState().user;
    if (user.isAuth) {
      dispatch({
        type: GET_DOCUMENT,
        payload: {
          loading: true,
        },
      });
      const response = await CRM.post("/get-docs", data);
      if (response.data.success) {
        return dispatch({
          type: GET_DOCUMENT,
          payload: {
            loading: false,
            get_Document: response.data.data.data,
            documentLoadMoreURL: response.data.data.next_page_url,
            /*  announcement_data: response.data.data,
              getAnnouncementLoadMore: response.data.data.next_page_url, */
            successMessage: response.data.message,
          },
        });
      }
    } else {
      return dispatch({
        type: GET_DOCUMENT,
        payload: {
          loading: false,
          get_Document: [],
          errorMessage: "Error: ",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DOCUMENT,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const loadMoreDocument = (url) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_DOCUMENT,
      payload: {
        loading: true,
      },
    });
    const endPoint = url.split("/");
    const response = await CRM.post(endPoint[endPoint.length - 1]);
    if (response.data.success) {
      const oldDocument = getState().training.get_Document;
      const newDocument = oldDocument.concat(response.data.data.data);
      dispatch({
        type: GET_DOCUMENT,
        payload: {
          loading: false,
          get_Document: newDocument,
          documentLoadMoreURL: response.data.data.next_page_url,
        },
      });
    } else {
      dispatch({
        type: GET_DOCUMENT,
        payload: {
          loading: false,
          errorMessage: "Error: " + response.data.message,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DOCUMENT,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const searchDocument = (query) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DOCUMENT,
      payload: {
        loading: true,
      },
    });
    const response = await CRM.post("/get-docs", {
      query: query,
    });
    if (response.data.success) {
      dispatch({
        type: GET_DOCUMENT,
        payload: {
          loading: false,
          get_Document: response.data.data.data,
          successMessage: response.data.message,
          errorMessage: "",
        },
      });
    } else {
      dispatch({
        type: GET_DOCUMENT,
        payload: {
          loading: false,
          get_Document: [],
          errorMessage: response.data.message,
          successMessage: "",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DOCUMENT,
      payload: {
        loading: false,
        get_Document: [],
        successMessage: "",
        errorMessage: "Error: " + err,
      },
    });
  }
};
