import React, { useState, /* useCallback ,*/ useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./routes/login/Login";
import ForgetPassword from "./routes/forgetpassword/ForgetPassword";
import LockScreen from "./routes/lockscreen/LockScreen";
import Dashboard from "./routes/dashboard/Dashboard";
import Profile from "./routes/profile/Profile";
import TrainingMaterial from "./routes/trainingmaterial/TrainingMaterial";
import TaskBoard from "./routes/taskboard/TaskBoard";
// import Chat from "./routes/chat/Chat";
import CalendarData from "./routes/calendar/CalendarData";
import RequireAuth from "./containers/RequireAuth";
import { tryLocalSignIn, userDetailData } from "./redux/actions/userAction";
import ResetPassword from "./routes/resetPassword/ResetPassword";
import { useThemeSwitcher } from "react-css-theme-switcher";
import Showsidebar from "./components/ShowSidebar";
import ChimeAuth from "./routes/chime-auth/chime-auth";
import Leads from "./routes/leads/leads";
// import { socket } from "./context/socket";
// import { updateContactOnlineStatus } from "./redux/actions/ContactActions";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // const myContacts = useSelector((state) => state.contacts.myContacts);
  // const [authID, setAuthID] = useState(undefined);
  // const userState = useSelector((state) => state.user);
  // const { userDetails } = userState;

  const [loading, setLoading] = useState(true);
  const isAuth = useSelector((state) => state.user.isAuth);
  const { switcher, themes } = useThemeSwitcher();

  useEffect(() => {
    dispatch(tryLocalSignIn());
  }, []);

  useEffect(() => {
    checkAuth();
  }, [isAuth]);

  useEffect(() => {
    checkTheme();
  }, []);

  const checkTheme = async () => {
    const themeData = await localStorage.getItem("theme");

    await switcher({
      theme: themeData === "true" ? themes.dark : themes.light,
    });
  };

  const checkAuth = async () => {
    const token = await localStorage.getItem("token");
    if (token && isAuth) {
      setLoading(false);
      if (location.pathname === "/") {
        navigate("/dashboard");
      }
      await dispatch(userDetailData());
    } else {
      setLoading(false);
      if (location.pathname === "/") {
        navigate("/dashboard");
      }
    }
  };

  //setting authenticated userID
  // useEffect(() => {
  //   if (userDetails && userDetails.user) setAuthID(userDetails.user.id);
  // }, [userDetails]);

  //letting socket server know about the connected user
  // useEffect(() => {
  //   if (authID !== undefined) socket.emit("login", { userId: authID });
  // }, [authID]);


  // const handleUserActiveStatus = useCallback((activeStatus, userID) => {
  //   dispatch(updateContactOnlineStatus(userID, activeStatus));
  // }, []);

  //subscribing for socket online/offline events
  // useEffect(() => {
  //   myContacts.map((user) =>
  //     socket.on("user_id_" + user.id, (activeStatus) =>
  //       handleUserActiveStatus(activeStatus, user.id)
  //     )
  //   );
  // }, [myContacts, handleUserActiveStatus]);

  return loading ? (
    <Spin className="loader-ld" />
  ) : (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/reset-password/:token/:email" element={<ResetPassword />} />
      <Route path="/lockscreen" element={<LockScreen />} />

      <Route element={<Showsidebar />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route element={<RequireAuth />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/training-material" element={<TrainingMaterial />} />
          <Route path="/taskboard" element={<TaskBoard />} />
          {/* <Route path="/chat" element={<Chat />} /> */}
          <Route path="/chime-auth" element={<ChimeAuth />} />
          <Route path="/leads" element={<Leads />} />
          <Route path="/calendardata" element={<CalendarData />} />
        </Route>

      </Route>
    </Routes>
  );
};

export default App;
