import React from "react";

import { Row, Col, Checkbox, Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const UpComing = () => {
  function onChange(checkedValues) {
    console.log("checked = ", checkedValues);
  }
  return (
    <div className="upcoming-letter">
      <div className="upcoming-heading">
        <h3>Upcoming</h3>
        <span>
          <CloseOutlined />
        </span>
      </div>
      <div className="taskboard-middle">
        <div>
          <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
            <Row>
              <Col span={24}>
                <div className="todo-list-uc">
                  <Checkbox value="A">
                    Nulla vitae purus
                    <Tag color="#0aca91">
                      <i className="fa fa-clock-o"></i> 1 day
                    </Tag>
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <div className="todo-list-uc">
                  <Checkbox value="B">
                    Nulla vitae purus
                    <Tag color="#2db7f5">
                      <i className="fa fa-clock-o"></i> 2 mins
                    </Tag>
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <div className="todo-list-uc">
                  <Checkbox value="C">
                    Nulla vitae purus
                    <Tag color="#e84a50">
                      <i className="fa fa-clock-o"></i> 3 days
                    </Tag>
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <div className="todo-list-uc">
                  <Checkbox value="D">
                    Nulla vitae purus
                    <Tag color="#f50">
                      <i className="fa fa-clock-o"></i> 2 mins
                    </Tag>
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <div className="todo-list-uc">
                  <Checkbox value="E">
                    Nulla vitae purus
                    <Tag color="#fad050">
                      <i className="fa fa-clock-o"></i> 4 mins
                    </Tag>
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <div className="todo-list-uc">
                  <Checkbox value="1">
                    Nulla vitae purus
                    <Tag color="#e9a472">
                      <i className="fa fa-clock-o"></i> 4 mins
                    </Tag>
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <div className="todo-list-uc">
                  <Checkbox value="2">
                    Nulla vitae purus
                    <Tag color="#25b5b5">
                      <i className="fa fa-clock-o"></i> 4 mins
                    </Tag>
                  </Checkbox>
                </div>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
      </div>
    </div>
  );
};
export default UpComing;
