import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Tabs, Skeleton, Pagination } from "antd";
import {
  getAnnouncement,
  loadMoreAnnouncementData,
} from "../../../redux/actions/dashboardAction";
import moment from "moment-timezone";
import { TIMEZONE } from "../../../config/actionTypes";
import ShowMoreText from "react-show-more-text";

const Announcements = () => {
  const dashboard_data = useSelector((state) => state.dashboard_data);
  const { loading, get_Announcement, announcement_data } = dashboard_data;
  //const [loadingAnnouncement, setLoadingAnnouncement] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    collectData();
  }, []);

  const collectData = async () => {
    /* setLoadingAnnouncement(false); */
    await dispatch(getAnnouncement());
  };

  const [currentAnnouncement, setCurrentAnnouncement] = useState(1);
  let setSizeAnnouncement = false;

  const sizeChangeLogic = (currentAnnouncement, size) => {
    setSizeAnnouncement = true;
    setCurrentAnnouncement(1);
  };

  const onChangeLogicAnnouncements = async (currentAnnouncement, size) => {
    !setSizeAnnouncement && setCurrentAnnouncement(currentAnnouncement);

    await dispatch(loadMoreAnnouncementData(currentAnnouncement));
  };

  return (
    <div className="news-letter">
      {loading ? (
        <Skeleton />
      ) : (
        <>
          {get_Announcement == "" ? (
            "No Data Found"
          ) : (
            <Row gutter={[0, 0]}>
              {get_Announcement.map((item) => (
                <>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="nld-heading">
                      <h2> {item.subject}</h2>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="post-news">
                      <p>
                        <ShowMoreText
                          lines={3}
                          more="Show more"
                          less="Show less"
                          expanded={false}
                          truncatedEndingComponent={"..."}

                          //onClick={executeScroll}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.message,
                            }}
                          ></div>
                        </ShowMoreText>
                        {/* {
                          <div
                            dangerouslySetInnerHTML={{ __html: item.message }}
                          />
                        } */}
                      </p>
                    </div>
                    <p className="date-time-link">
                      {moment.tz(item.created_at, TIMEZONE).format("ll")}
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                      {moment.tz(item.created_at, TIMEZONE).format("LT")}
                    </p>
                  </Col>
                </>
              ))}
            </Row>
          )}
          {announcement_data && announcement_data.next_page_url != null ? (
            <Pagination
              pageSize={
                announcement_data && announcement_data.per_page != null
                  ? announcement_data.per_page
                  : null
              }
              size="small"
              total={
                announcement_data && announcement_data.total != null
                  ? announcement_data.total
                  : null
              }
              showTotal={(total) => `Total ${total} items`}
              current={currentAnnouncement}
              onChange={onChangeLogicAnnouncements}
            />
          ) : null}
        </>
      )}
    </div>
  );
};
export default Announcements;
