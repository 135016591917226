import React from "react";
import { Row, Col, Tabs, Input, Space } from "antd";
import Video from "./component/Video";
import Documents from "./component/Documents";
import {
  searchVideo,
  searchDocument,
} from "../../redux/actions/trainingAction";
import { useDispatch } from "react-redux";

const TrainingMaterial = () => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  function callback(key) {
    console.log(key);
  }
  const { Search } = Input;
  const onSearchVideo = async (e) => {
    await dispatch(searchVideo(e.target.value));
  };
  const onSearchDocument = async (e) => {
    await dispatch(searchDocument(e.target.value));
  };

  return (
    <div className="content_all">
      <Row gutter={[26, 20]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h3 className="page-title">Training Material</h3>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className="">
            <div className="tabs_dashboard-training">
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Videos" key="1">
                  <div className="searchbar-sb targetsearch-fix0">
                    <Search
                     allowClear
                      placeholder="Search"
                      //onChange={(e) => onSearch(e.target.value)}
                      onChange={onSearchVideo}
                    />
                  </div>
                  <div className="video-doc-vd">
                    <Row gutter={[16, 1]}>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Video />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab="Documents" key="2">
                  <div className="searchbar-sb targetsearch-fix0">
                    <Search
                     allowClear
                      placeholder="Search"
                      // onChange={(e) => onSearch(e.target.value)}
                      onChange={onSearchDocument}
                    />
                  </div>
                  <div className="video-doc-vd">
                    <Row gutter={[16, 1]}>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Documents />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default TrainingMaterial;
