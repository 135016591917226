import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Layout, Menu, Col, Row, Button, Drawer } from "antd";
import {
  UserOutlined,
  DashboardOutlined,
  MessageOutlined,
  FileMarkdownOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

import { AiOutlineMenu } from "react-icons/ai";
import { useSelector } from "react-redux";
import UserDropdown from "./UserDropdown";
import Notification from "./Notification";
import { Link } from "react-router-dom";

const SidebarMobile = () => {
  const [isModalVisibleDrawer, setIsModalVisibleDrawer] = useState(false);

  const { SubMenu } = Menu;
  const { Header } = Layout;
  const user = useSelector((state) => state.user);
  const { isAuth } = user;
  const chime_auth = localStorage.getItem("auth");
  return (
    <div>
      <Layout>
        <Layout className="site-layout">
          <Header className="site-layout-background navbar_top">
            <Row
              gutter={[0, 0]}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Col xs={3} sm={3} md={0} lg={0}>
                <div className="mobile-sibebar-mb">
                  <Button
                    className="menu"
                    type="primary"
                    icon={<AiOutlineMenu />}
                    onClick={() => setIsModalVisibleDrawer(true)}
                  />
                  <Drawer
                    placement="left"
                    onClick={() => setIsModalVisibleDrawer(false)}
                    onClose={() => setIsModalVisibleDrawer(false)}
                    visible={isModalVisibleDrawer}
                    className="mobile-sidebar-0"
                  >
                    <Menu mode="inline" defaultOpenKeys={["sub2"]}>
                      <Menu.Item key="1" icon={<DashboardOutlined />}>
                        <Link to="/dashboard">Dashboard</Link>
                      </Menu.Item>
                      <SubMenu
                        key="sub2"
                        icon={<FileMarkdownOutlined />}
                        title="My Agenda"
                      >
                        <Menu.Item key="5">
                          <Link to="/leads"> Leads </Link>
                        </Menu.Item>
                        {Boolean(chime_auth) ? (
                          <Menu.Item key="6">
                            <Link to="/calendardata"> Calendar</Link>
                          </Menu.Item>
                        ) : null}
                        {/* <Menu.Item key="7">
                          <Link to="/taskboard">Task Board</Link>
                        </Menu.Item> */}
                      </SubMenu>

                      <Menu.Item key="8" icon={<VideoCameraOutlined />}>
                        <Link to="/training-material">Training</Link>
                      </Menu.Item>
                      {/* <Menu.Item key="3" icon={<MessageOutlined />}>
                        <Link to="/chat">Chat</Link>
                      </Menu.Item> */}
                      <Menu.Item key="4" icon={<UserOutlined />}>
                        <Link to="/profile"> Profile</Link>
                      </Menu.Item>
                    </Menu>
                  </Drawer>
                </div>
              </Col>
              <Col xs={8} sm={8} md={12} lg={12}>
                <div className="togglebtn0">
                  <div className="logouser">Agent panel</div>
                </div>
              </Col>
              <Col xs={13} sm={13} md={12} lg={12}>
                {isAuth ? (
                  <div className="noti-userdrpdown">
                    <Notification />
                    <UserDropdown />
                  </div>
                ) : (
                  <div className="login-userdrpdown">
                    <Link to="/login">Login to get access</Link>
                  </div>
                )}
              </Col>
            </Row>
          </Header>
          <Outlet />
        </Layout>
      </Layout>
    </div>
  );
};

export default SidebarMobile;
