import { message } from "antd";
import {
  MANAGE_GROUP_CHAT,
  PREPEND_CONVERSATION_ARRAY_GROUP,
  UPDATE_CONVERSATION_ARRAY_GROUP,
  UPDATE_SELECTED_CONVERSATION_GROUP,
  UPDATE_GROUP_CONVER,
  UPDATE_GROUP_CONVERSATION,
  UPLOAD_FILE_IN_GROUP,
  REMOVE_FILE_FROM_GROUP,
  UPDATE_GROUP_UNREAD_COUNT,
} from "../../config/actionTypes";

const INITIAL_STATE = {
  loading: false,
  groupConversations: [],
  selectedGroupConversation: {},
  successMessage: "",
  errorMessage: "",
  unreadCountGroup: 0,
  groupChatUploadImage: [],
  isGroupMediaUploading: false,
  groupMessageLastID: 0,
  groupMessageSkip: 1,
  GMhasMore: undefined,
  scrollFlag: true,
};

const GroupChat = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MANAGE_GROUP_CHAT:
      return { ...state, ...action.payload };
    case UPLOAD_FILE_IN_GROUP:
      return {
        ...state,
        groupChatUploadImage: [...state.groupChatUploadImage, action.payload],
      };

    case REMOVE_FILE_FROM_GROUP:
      var myArray = state.groupChatUploadImage.filter(function (obj) {
        return obj.id !== action.payload;
      });
      return {
        ...state,
        groupChatUploadImage: myArray,
      };
    case PREPEND_CONVERSATION_ARRAY_GROUP:
      return {
        ...state,
        groupConversations: [action.payload, ...state.groupConversations],
      };
    case UPDATE_CONVERSATION_ARRAY_GROUP:
      var myArray = state.groupConversations.filter(function (obj) {
        return obj.id !== action.payload.id;
      });
      return {
        ...state,
        groupConversations: [action.payload, ...myArray],
      };
    case UPDATE_SELECTED_CONVERSATION_GROUP:
      return {
        ...state,
        selectedGroupConversation: {
          ...state.selectedGroupConversation,
          group_messages: [
            ...state.selectedGroupConversation.group_messages,
            action.payload,
          ],
        },
      };
    case UPDATE_GROUP_CONVER:
      return {
        ...state,
        groupConversationLoadMore: action.payload.groupConversationLoadMore,
        groupConversations: [
          ...state.groupConversations.concat(action.payload.groupConversations),
        ],
      };
    case UPDATE_GROUP_CONVERSATION:
      return {
        ...state,
        groupMessageLastID: action.payload.groupMessageLastID,
        groupMessageSkip: action.payload.groupMessageSkip,
        groupMessageFinished: action.payload.dataFinished,
        selectedGroupConversation: {
          ...state.selectedGroupConversation,
          group_messages: [
            ...action.payload.messages,
            ...state.selectedGroupConversation.group_messages,
          ],
        },
      };
    case UPDATE_GROUP_UNREAD_COUNT:
      return {
        ...state,
        groupConversations: state.groupConversations.map((item) =>
          item.id === action.payload
            ? { ...item, unread_messages_count: 0 }
            : item
        ),
      };
    default:
      return state;
  }
};

export default GroupChat;
