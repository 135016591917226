import React, { useEffect, useState } from "react";
import { Row, Col, Calendar, Badge, Popover, Select } from "antd";
import { CHIME_CLIENT_ID } from "../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsData, getLeadsTask } from "../../redux/actions/ChimeAction";
import moment from "moment-timezone";
import { TIMEZONE } from "../../config/actionTypes";
import { useNavigate } from "react-router-dom";

const CalendarData = () => {
  const leads_id = window.location.href.split("=");
  const disptach = useDispatch();
  const navigate = useNavigate();
  const chime_token = localStorage.getItem("auth");
  const leads_tasklist = useSelector((state) => state.leads.leadTaskList);
  const leads_list = useSelector((state) => state.leads.leads);
  const [filter, setFilter] = useState(0);
  const { Option } = Select;
  moment.tz.setDefault(TIMEZONE);

  useEffect(() => {
    const data = {
      access_token: chime_token,
      all: 1,
    };
    disptach(getLeadsData(data));
  }, [chime_token]);

  useEffect(() => {
    
    getLeads();
  }, [leads_list]);

  const getLeads = async () => {
    if (leads_id.length == 1 && leads_list != undefined) {

      if(leads_list && leads_list[0] && leads_list[0].leadId){
        console.log("defulat id -------",leads_list[0].leadId)
        setFilter(leads_list[0].leadId)
      }
        
      var getleads = leads_list[0].leadId;
      console.log("test leads_is", leads_list[0].leadId);
    } else {
      var getleads = leads_id[leads_id.length - 1];
    }
    const data = {
      access_token: chime_token,
      leadId: getleads,
    };

    disptach(getLeadsTask(data));
  };

  // console.log("leads_tasklist-------",leads_tasklist)

  function getListData(value) {
    var listData = [];
    if (leads_tasklist != null) {
      leads_tasklist.taskList &&
        leads_tasklist.taskList.map((item, i) => {
          // console.log("data-----");
          const dates = item.deadline.split("GMT");
          const time = moment.tz(dates[0], TIMEZONE).format("yyyy-MM-DD");
          if (time == value.tz(TIMEZONE).format("yyyy-MM-DD")) {
            console.log("pass-----taskList");
            let date = item.deadline.split("GMT");
            listData.push({
              type: "success",
              content: item.content,
              deadline: date[0],
              lead: `${item.leadFirstName} ${item.leadLastName}`,
              assignedUser: item.assignedUser,
              assignedRole: item.assignedRole,
              leadPhone: item.leadPhone,
            });
          }
        });

      leads_tasklist.local_events &&
        leads_tasklist.local_events.map((item, i) => {
          const time = moment
            .tz(item.event_date, TIMEZONE)
            .format("yyyy-MM-DD");
          // console.log(
          //   "date-----",
          //   time,
          //   "===",
          //   value.tz(TIMEZONE).format("yyyy-MM-DD")
          // );
          if (time == value.tz(TIMEZONE).format("yyyy-MM-DD")) {
            console.log("pass-----local_events");

            listData.push({
              title: item.title,
              description: item.description,
            });
          }
        });
    }
    // console.log("listData------", listData);
    return listData || [];
  }

  function dateCellRender(value) {
    const listData = getListData(value);
    // console.log("leads task------------------2", listData);

    return (
      <ul className="events">
        {listData.map((item) => {
          const content =
            item.description && item.description ? (
              <div className="popover-tool-pl">
                <div className="date-time-dt">
                  <h4 className="Name__dt">Title :</h4>
                  <p className="fullname__dt"> {item.title}</p>
                </div>
                <div className="date-time-dt">
                  <h4 className="Name__dt">Desc :</h4>
                  <p className="fullname__dt">{item.description}</p>
                </div>
              </div>
            ) : (
              <div className="popover-tool-pl">
                <div className="date-time-dt">
                  <h4 className="Name__dt">Name :</h4>
                  <p className="fullname__dt"> {item.assignedRole}</p>
                </div>
                <div className="date-time-dt">
                  <h4 className="Name__dt">Date :</h4>
                  <p className="fullname__dt">
                    {moment(item.deadline).format("ll")}
                  </p>
                </div>
                <div className="date-time-dt">
                  <h4 className="Name__dt">Lead :</h4>
                  <p className="fullname__dt">{item.lead}</p>
                </div>
                <div className="date-time-dt">
                  <h4 className="Name__dt">Agent :</h4>
                  <p className="fullname__dt"> {item.assignedUser}</p>
                </div>
                <div className="date-time-dt">
                  <h4 className="Name__dt">Description :</h4>
                  <p className="fullname__dt">
                    <div className="description-d0">{item.content}</div>
                    {/* <div className="viewmorem0">
                    <Button>View More</Button>
                  </div> */}
                  </p>
                </div>
                <div className="date-time-dt">
                  <h4 className="Name__dt">Phone :</h4>
                  <p className="fullname__dt"> {item.leadPhone}</p>
                </div>
              </div>
            );

          return (
            <Popover
              content={content}
              className="edit-popover-ep0"
              // title="Title"
              trigger="click"
            >
              <li key={item.content}>
                {item.description && item.description ? (
                  <Badge
                    color={"red"}
                    status={item.type}
                    text={item.description}
                  />
                ) : (
                  <Badge
                    color={"blue"}
                    status={item.type}
                    text={item.content}
                  />
                )}
              </li>
            </Popover>
          );
        })}
      </ul>
    );
  }

  function getMonthData(value) {
    if (value.month() === 8) {
      return /* 1394 */;
    }
  }

  function monthCellRender(value) {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        {/*   <span>Backlog number</span> */}
      </div>
    ) : null;
  }
  
  const [ filterALL, setfilterALL ] =  useState(0);
  const handleChange = (id) => {
    
    setFilter(id);
    let data = {
      access_token: chime_token,
      leadId: id,
      all: filterALL
    };
    disptach(getLeadsTask(data));
  };

  const taskFilter = (id) => {
    setfilterALL(id)
    let data = {
      access_token: chime_token,
      leadId: filter,
      all: id,
    };
    disptach(getLeadsTask(data));
  };

  const menu = (
    <Select
      placeholder="Select leads"
      style={{ width: 120 }}
      onChange={handleChange}
      // defaultValue={
      //   leads_list && leads_list[0] && leads_list[0].leadId
      //     ? leads_list[0].leadId
      //     : null
      // }
    >
      {console.log(
        "leads_list[0].leadId---------",
        leads_list && leads_list[0] && leads_list[0].leadId
          ? leads_list[0].leadId
          : 0
      )}
      {leads_list.map((item, i) => {
        return (
          <Option
            value={item.leadId}
          >{`${item.firstName} ${item.lastName}`}</Option>
        );
      })}
    </Select>
  );

  useEffect(() => {

    if (chime_token == undefined) {
      navigate("/leads");
    }
  }, [chime_token]);

  return (
    <div className="content_all">
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className="calendar-data-cd">

            <Select
              placeholder="Select leads"
              style={{ width: 120 }}
              onChange={taskFilter}
              defaultValue={0}
            >
              <Option value={0}>My Task</Option>
              <Option value={1}>All Task</Option>
            </Select>
            {menu}
            {Boolean(chime_token) ? (
              <Calendar
                dateCellRender={dateCellRender}
                monthCellRender={monthCellRender}
              />
            ) : (
              <a
                className="ant-btn ant-btn-primaryant-btn ant-btn-primary"
                href={`https://chime.me/page/vendor-auth.html?clientId=${CHIME_CLIENT_ID}`}
              >
                authenticate with chime
              </a>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default CalendarData;
