import {
  UPDATE_AUTH,
  USER_DETAIL,
  THEME_UPDATE,
} from "../../config/actionTypes";
import CRM from "../../api/crm";
import { message } from "antd";

export const login = (data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_AUTH,
      payload: {
        loading: true,
      },
    });
    const response = await CRM.post("/login", data);
    if (response.data.success) {
      await localStorage.setItem("token", response.data.data);

      dispatch({
        type: UPDATE_AUTH,
        payload: {
          loading: false,
          isAuth: true,
          successMessage: response.data.message,
        },
      });
      message.success(response.data.message);
    } else {
      dispatch({
        type: UPDATE_AUTH,
        payload: {
          loading: false,
          isAuth: false,
          errorMessage: "Error: " + response.data.message,
        },
      });
      message.error(response.data.message);
    }
  } catch (err) {
    dispatch({
      type: UPDATE_AUTH,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const tryLocalSignIn = () => async (dispatch) => {
  const token = await localStorage.getItem("token");

  if (token) {
    return dispatch({
      type: UPDATE_AUTH,
      payload: {
        loading: false,
        isAuth: true,
      },
    });
  }
};

export const logout = () => async (dispatch) => {
  await localStorage.removeItem("token");
  await localStorage.removeItem('auth');
  dispatch({
    type: UPDATE_AUTH,
    payload: {
      isAuth: false,
      userDetail: {},
      notifications: [],
      successMessage: "",
      errorMessage: "",
    },
  });
  message.success("You have successfully Logged out!");
};

export const forgetPassword = (data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_AUTH,
      payload: {
        loading: true,
      },
    });
    const response = await CRM.post("/forgot-password", data);
    if (response.data.success) {
      dispatch({
        type: UPDATE_AUTH,
        payload: {
          loading: false,
        },
      });
      message.success(response.data.message);
    } else {
      dispatch({
        type: UPDATE_AUTH,
        payload: {
          loading: false,
        },
      });
      message.error(response.data.message);
    }
  } catch (err) {
    dispatch({
      type: UPDATE_AUTH,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
    message.error("Error: " + err);
  }
};

export const resetPassword = (data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_AUTH,
      payload: {
        loading: true,
      },
    });
    const response = await CRM.post("/reset-password", data);
    if (response.data.success) {
      dispatch({
        type: UPDATE_AUTH,
        payload: {
          loading: false,
        },
      });
      message.success(response.data.message);
    } else {
      dispatch({
        type: UPDATE_AUTH,
        payload: {
          loading: false,
        },
      });
      message.error(response.data.message);
    }
  } catch (err) {
    dispatch({
      type: UPDATE_AUTH,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
    message.error("Error: " + err);
  }
};

export const userDetailData = (data) => async (dispatch, getState) => {
  try {
    const user = await getState().user;

    if (user.isAuth) {
      dispatch({
        type: USER_DETAIL,
        payload: {
          loading: true,
        },
      });

      const response = await CRM.get("/get-user");

      if (response.data.success) {
        dispatch({
          type: USER_DETAIL,
          payload: {
            loading: false,
            loadedFlag: true,
            userDetails: response.data.data,
            successMessage: response.data.message,
          },
        });
      } else {
        dispatch({
          type: USER_DETAIL,
          payload: {
            loading: false,
            userDetails: {},
            errorMessage: "Error: " + response.data.message,
          },
        });
      }
    } else {
      return dispatch({
        type: USER_DETAIL,
        payload: {
          loading: false,
          userDetails: {},
          errorMessage: "Please login !",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: USER_DETAIL,
      payload: {
        loading: false,
        userDetails: {},
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const userDetailUpdate = (data) => async (dispatch, getState) => {
  try {
    const user = await getState().user;

    if (user.isAuth) {
      dispatch({
        type: USER_DETAIL,
        payload: {
          loading: true,
        },
      });

      const response = await CRM.post("/update-profile", data);

      if (response.data.success) {
        dispatch({
          type: USER_DETAIL,
          payload: {
            loading: false,
            loadedFlag: true,
            userDetails: response.data.data,
            successMessage: response.data.message,
          },
        });

        message.success(response.data.message);
      } else {
        message.error(response.data.message);
        dispatch({
          type: USER_DETAIL,
          payload: {
            loading: false,
            errorMessage: "Error: " + response.data.message,
          },
        });
      }
    } else {
      return dispatch({
        type: USER_DETAIL,
        payload: {
          loading: false,
          errorMessage: "Please login !",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: USER_DETAIL,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const userFirstLogin = (data) => async (dispatch, getState) => {
  try {
    const user = await getState().user;

    if (user.isAuth) {
      dispatch({
        type: USER_DETAIL,
        payload: {
          loading: true,
        },
      });

      const response = await CRM.post("/first-update", data);

      if (response.data.success) {
        dispatch({
          type: USER_DETAIL,
          payload: {
            loading: false,
            loadedFlag: true,
            userDetails: response.data.data,
            successMessage: response.data.message,
          },
        });

        message.success(response.data.message);
      } else {
        message.error(response.data.message);
        dispatch({
          type: USER_DETAIL,
          payload: {
            loading: false,
            errorMessage: "Error: " + response.data.message,
          },
        });
      }
    } else {
      return dispatch({
        type: USER_DETAIL,
        payload: {
          loading: false,
          errorMessage: "Please login !",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: USER_DETAIL,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};

export const themeChange = () => async (dispatch, getState) => {
  const theme = await localStorage.getItem("theme");
  dispatch({
    type: THEME_UPDATE,
    payload: {
      themeChange: theme,
    },
  });
};

export const removeProfilePicture = (data) => async (dispatch, getState) => {
  try {
    const user = await getState().user;

    if (user.isAuth) {
      dispatch({
        type: USER_DETAIL,
        payload: {
          loading: true,
        },
      });

      const response = await CRM.post("/remove-avatar", data);

      if (response.data.success) {
        dispatch({
          type: USER_DETAIL,
          payload: {
            loading: false,
            loadedFlag: true,
            //userDetails: response.data.data,
            successMessage: response.data.message,
          },
        });

        message.success(response.data.message);
      } else {
        message.error(response.data.message);
        dispatch({
          type: USER_DETAIL,
          payload: {
            loading: false,
            errorMessage: "Error: " + response.data.message,
          },
        });
      }
    } else {
      return dispatch({
        type: USER_DETAIL,
        payload: {
          loading: false,
          errorMessage: "Please login !",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: USER_DETAIL,
      payload: {
        loading: false,
        errorMessage: "Error: " + err,
      },
    });
  }
};
