import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import AppLayout from "./AppLayout";
import SidebarMobile from "./SidebarMobile";
function Showsidebar() {
  const { width } = useWindowDimensions();
  var sidebar;
  width > 770 ? (sidebar = <AppLayout />) : (sidebar = <SidebarMobile />);
  return <div>{sidebar}</div>;
}
export default Showsidebar;
