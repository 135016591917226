import {
  GET_DASHBOARD,
  GET_NEWS,
  GET_ANNOUNCEMENT,
  GET_VALUES,
  GET_MISSION,
} from "../../config/actionTypes";

const INITIAL_STATE = {
  loading: false,

  get_News: {},
  get_Announcement: [],
  get_Value: [],
  get_Values: {},
  getdashboard: {},
  get_Mission: [],
  getNewsLoadMore: null,
  getValuesLoadMore: null,
  getMissionLoadMore: null,
  getAnnouncementLoadMore: null,
  News_data: [],
  missionData: {},
};

const dashboard = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /*  case GET_DASHBOARD:
      const get_Dashboard = { ...state, ...action.payload };
      return get_Dashboard; */
    case GET_MISSION:
      const get_Mission = { ...state, ...action.payload };
      return get_Mission;
    case GET_NEWS:
      const get_News = { ...state, ...action.payload };
      return get_News;
    case GET_VALUES:
      const get_Values = { ...state, ...action.payload };
      return get_Values;

    case GET_ANNOUNCEMENT:
      const get_Announcement = { ...state, ...action.payload };
      return get_Announcement;
    /*  case GET_VALUES:
      const get_Values = { ...state, ...action.payload };
      return get_Values; */

    default:
      return state;
  }
};

export default dashboard;
