import React from "react";
import { Form, Input, Button, Row, Col, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../../style/login.css";
const LockScreen = () => {
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };
  return (
    <div className="login_contnainer">
      <Row
        gutter={[6, 6]}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col xs={24} sm={24} md={10} lg={10}>
          <div className="login-form">
            <Row gutter={[6, 6]}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <div className="content-top-agile">
                  <img
                    src="https://master-admin-template.multipurposethemes.com/bs4/ltr/images/avatar/2.jpg"
                    alt=""
                  />
                  <h3>Test Account</h3>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form
                  name="normal_login"
                  className="frm-Login"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                >
                  <Row gutter={[0, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                        ]}
                      >
                        <Input
                          prefix={
                            <LockOutlined className="site-form-item-icon" />
                          }
                          type="password"
                          placeholder="Password"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item>
                        <div className="login-form-button">
                          <Button type="primary" htmlType="submit">
                            Sign In
                          </Button>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <div className="sign-with">
                        <p>- OR -</p>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <div className="account-li ">
                        Enter your password to retrieve your session
                      </div>
                      <p className="account-li">
                        Or
                        <Link to="#"> Sign In </Link>
                        as a Different User
                      </p>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default LockScreen;
