import { message } from "antd";
import moment from "moment";
import {
  MANAGE_DIRECT_CHAT,
  PREPEND_CONVERSATION_ARRAY,
  UPDATE_CONVERSATION_ARRAY,
  UPDATE_SELECTED_CONVERSATION,
  UPDATE_CONVERSATION_INFINITY,
  DIRECT_CHAT_INFINITY,
  UPLOAD_FILE_IN_CONVERSATION,
  REMOVE_FILE_FROM_CONVERSATION,
  UPDATE_DIRECT_UNREAD_COUNT,
  UPDATE_SELECTED_CHAT_ACTIVE_STATUS,
  TIMEZONE,
} from "../../config/actionTypes";

const INITIAL_STATE = {
  loading: false,
  conversations: [],
  selectedConversationMessages: [],
  conversationLoadMore: null,
  selectedConversationReceiverData: {},
  selectedConversationReceiverDetails: {},
  unreadCount: 0,
  isDirectVisible: true,
  successMessage: "",
  errorMessage: "",
  conversationMessageLoadMore: null,
  directChatUploadImage: [],
  isMediaUploading: false,
  conversationMessageLastID: 0,
  conversationMessageSkip: 1,
  DMhasMore: undefined,
  scrollFlag: true,
};

const DirectChat = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MANAGE_DIRECT_CHAT:
      return { ...state, ...action.payload };

    case UPLOAD_FILE_IN_CONVERSATION:
      return {
        ...state,
        directChatUploadImage: [...state.directChatUploadImage, action.payload],
      };

    case REMOVE_FILE_FROM_CONVERSATION:
      var myArray = state.directChatUploadImage.filter(function (obj) {
        return obj.id !== action.payload;
      });
      return {
        ...state,
        directChatUploadImage: myArray,
      };

    case PREPEND_CONVERSATION_ARRAY:
      return {
        ...state,
        conversations: [action.payload, ...state.conversations],
      };
    case UPDATE_CONVERSATION_ARRAY:
      var myArray = state.conversations.filter(function (obj) {
        return obj.id !== action.payload.id;
      });
      return {
        ...state,
        conversations: [action.payload, ...myArray],
      };
    case UPDATE_SELECTED_CONVERSATION:
      return {
        ...state,
        selectedConversationMessages: [
          ...state.selectedConversationMessages,
          action.payload,
        ],
      };
    case UPDATE_CONVERSATION_INFINITY:
      return {
        ...state,
        selectedConversationMessages: [
          ...action.payload.message,
          ...state.selectedConversationMessages,
        ],
        conversationMessageLastID: action.payload.conversationMessageLastID,
        conversationMessageSkip: action.payload.conversationMessageSkip,
      };
    case DIRECT_CHAT_INFINITY:
      return {
        ...state,
        conversationLoadMore: action.payload.nextPageUrl,
        conversations: [
          ...state.conversations.concat(action.payload.conversations),
        ],
      };
    case UPDATE_DIRECT_UNREAD_COUNT:
      return {
        ...state,
        conversations: state.conversations.map((item) =>
          item.id === action.payload
            ? { ...item, unread_messages_count: 0 }
            : item
        ),
      };
    case UPDATE_SELECTED_CHAT_ACTIVE_STATUS:
      return {
        ...state,
        selectedConversationReceiverData: {
          ...state.selectedConversationReceiverData,
          is_online: action.payload,
          last_seen: moment.utc().format(),
        },
      };
    default:
      return state;
  }
};

export default DirectChat;
