import React, { Component } from "react";
import HLSSource from "./HLSSource";
import "video-react/dist/video-react.css";
import { Player } from "video-react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

class Players extends Component {
  render() {
    const { open, toggleModal, url, id } = this.props;

    return (
      <Modal
        size="lg"
        show={open}
        onHide={() => toggleModal(false)}
        dialogClassName={id}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Body className="m-0 p-0 model-boxvideo0">
          <Player autoPlay={true}>
            <source src={url} />
            {/* <HLSSource isVideoChild src={url} /> */}
          </Player>
        </Modal.Body>
      </Modal>
    );
  }
}

export default Players;
