import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Menu,
  Dropdown,
  Form,
  Input,
  Button,
  Skeleton,
  Modal,
} from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
  InboxOutlined,
} from "@ant-design/icons";

import {
  userDetailData,
  userFirstLogin,
  logout,
} from "../redux/actions/userAction";
import { UPLOAD_PIC } from "../config/actionTypes";
import { AVATAR_URL } from "../config/constants";
import pic from "../img/pic.jpg";
import { Link } from "react-router-dom";
import { BiVideo } from "react-icons/bi";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import DefaultAvatar from "./DefaultAvatar";
// import { socket } from "../context/socket";

const UserDropdown = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { loading, userDetails } = user;
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  /* useEffect(() => {
    collectSocailmediaData();
  }, []);

  const collectSocailmediaData = async () => {
    dispatch(userDetailData());
  }; */

  const unsubscribe = async () => {
    try {
      await window.CometChatWidget.logout();
    } catch (error) { }
    // socket.emit("logout", { userId: userDetails.user.id });
    dispatch(logout());
    window.location.reload();
  };


  const menu = (
    <Menu className="dropdown-downmenu0">
      <Menu.Item key="0">
        <Link to="/profile">
          <UserOutlined />
          Profile
        </Link>
      </Menu.Item>
      {/*  <Menu.Item key="1">
        <a href="#">
          <InboxOutlined />
          Inbox
        </a>
      </Menu.Item> */}

      <Menu.Item key="3">
        <Link to="/profile">
          <SettingOutlined />
          Settings
        </Link>
      </Menu.Item>
      <Menu.Item key="4" onClick={() => unsubscribe()}>
        <LogoutOutlined />
        Logout
      </Menu.Item>
    </Menu>
  );

  /* First login */

  useEffect(() => {
    collectSocailmediaData();
  }, [userDetails]);

  const collectSocailmediaData = async () => {
    if (Object.keys(userDetails).length !== 0) {
      const formObj = {
        fname:
          userDetails && userDetails.user && userDetails.user.fname != ""
            ? userDetails.user.fname
            : null,
        lname:
          userDetails && userDetails.user && userDetails.user.lname != ""
            ? userDetails.user.lname
            : null,
        /* email:
          userDetails && userDetails.user && userDetails.user.email != ""
            ? userDetails.user.email
            : null, */
        phone:
          userDetails &&
            userDetails.user_details &&
            userDetails.user_details.phone != ""
            ? userDetails.user_details.phone
            : null,
        /*  phone: userDetails.user_details.phone, */
      };
      console.log(formObj);
      await form.setFieldsValue(formObj);
    }
  };

  const onFinish = async (values) => {
    const newData = {
      ...values,
    };
    await dispatch(userFirstLogin(newData));
    dispatch(userDetailData());
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (userDetails && userDetails.user && userDetails.user.first_login == 0) {
      setIsModalVisible(true);
    }
  }, [userDetails]);

  return (
    <div className="nav_dropuser">
      <Dropdown overlay={menu} trigger={["click"]}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <div className="image_nv">
            {loading ? (
              <Skeleton.Avatar />
            ) : (
              <>
                {userDetails &&
                  userDetails.user_details &&
                  userDetails.user_details.avatar != null ? (
                  <img
                    src={
                      userDetails &&
                        userDetails.user_details &&
                        userDetails.user_details.avatar != null
                        ? AVATAR_URL + userDetails.user_details.avatar
                        : pic
                    }
                    alt=""
                  />
                ) : (
                  <DefaultAvatar
                    fname={
                      userDetails &&
                        userDetails.user &&
                        userDetails.user.fname != ""
                        ? userDetails.user.fname
                        : null
                    }
                    lname={
                      userDetails &&
                        userDetails.user &&
                        userDetails.user.fname != ""
                        ? userDetails.user.lname
                        : null
                    }
                  />
                )}
              </>
            )}
          </div>

          <div className="name-fl-l">
            {loading ? (
              <Skeleton.Input style={{ width: 100 }} />
            ) : (
              <>
                <p>
                  {userDetails &&
                    userDetails.user &&
                    userDetails.user.fname !== ""
                    ? userDetails.user.fname
                    : "N/A"}
                </p>
                <p>
                  {userDetails && userDetails.user && userDetails.lname !== ""
                    ? userDetails.user.lname
                    : "N/A"}
                </p>
              </>
            )}
          </div>
        </a>
      </Dropdown>
      <Modal
        title=" Update "
        visible={isModalVisible}
        footer={null}
        closable={null}
      >
        <Form name="basic" onFinish={onFinish} layout="vertical" form={form}>
          <Form.Item
            label="First Name"
            name="fname"
            rules={[
              {
                required: true,
                message: "Please input first name!",
              },
              {
                max: 20,
                message: "First Name must be maximum 20 digits.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lname"
            rules={[
              {
                required: true,
                message: "Please input last name!",
              },
              {
                max: 20,
                message: "Last Name must be maximum 20 digits.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <Input />
            </Form.Item> */}
          <Form.Item
            name="phone"
            label="Phone  "
            required={true}
            rules={[
              {
                required: true,
                message: "This field is Mandatory!",
              },
              {
                min: 10,
                message: "Phone number must be minimum 10 digits.",
              },
              {
                max: 12,
                message: "Phone number must be maximum 12 digits.",
              },
              {
                pattern: "^[0-9]+$",
                message: "Please input a valid phone number",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label=" Password"
            required={true}
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
              {
                min: 8,
                message: "Password must be minimum 8 characters.",
              },
              {
                pattern: "^[a-zA-Z0-9_]*$",
                message: "Please input only letters or numbers. Thank you!",
              },
            ]}
          >
            <Input.Password
              placeholder="Enter your password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item
            name="c_password"
            label="Confirm Password"
            required={true}
            rules={[
              {
                required: true,
                message: "Please input your Confirm Password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error("Password does not match!"));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Enter Confirm password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>

          <Form.Item>
            <div className="button__area2">
              <Button htmlType="submit">Submit</Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default UserDropdown;
