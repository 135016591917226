import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./App.css";
import "./style/style.css";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/configureStore";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

const store = configureStore();
const renderApp = () =>
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme="light">
          <App />
        </ThemeSwitcherProvider>
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );

renderApp();

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./App", renderApp);
}

/* ReactDOM.render(
  <Provider store={store}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);


reportWebVitals(); */
