import React from "react";

import { Row, Col, Tabs } from "antd";
import UpComing from "./component/UpComing";
import InProgress from "./component/InProgress";
import Complete from "./component/Complete";
const TaskBoard = () => {
  return (
    <div className="content_all">
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h3 className="page-title">Task Board</h3>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <UpComing />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <InProgress />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12}>
          <Complete />
        </Col>
      </Row>
    </div>
  );
};
export default TaskBoard;
