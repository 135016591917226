import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Tabs, Spin, Button, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { TIMEZONE, UPLOAD_VIDEO } from "../../../config/actionTypes";
import moment from "moment-timezone";
import ShowMoreText from "react-show-more-text";
import { getVideo, loadMoreVideo } from "../../../redux/actions/trainingAction";
import "video-react/dist/video-react.css";
import { ReloadOutlined } from "@ant-design/icons";
import { API_URL, BASE_URL } from "../../../config/constants";
import Players from "./VideoModal";
import { Player } from "video-react";
import { CaretRightOutlined } from "@ant-design/icons";

const Video = () => {
  const dispatch = useDispatch();
  const training = useSelector((state) => state.training);

  const [modalVisible, setModalVisible] = useState(false);
  const [ModalVideo, setModalVideo] = useState("");

  const { get_Video, loading, videoLoadMoreURL } = training;

  useEffect(() => {
    collectVideo();
  }, []);

  const onOpenModal = () => {
    setModalVisible(!modalVisible);
  };
  const jk = (item) => {
    console.log("item", item);
    setModalVideo(item);
    setModalVisible(true);
  };

  const collectVideo = async () => {
    await dispatch(getVideo());
  };
  return (
    <div className="video-letter">
      {loading ? (
        <Spin className="loader-ld" />
      ) : (
        <>
          {get_Video === "" ? (
            "No Data Found"
          ) : (
            <Row gutter={[16, 1]}>
              {get_Video.map((item) => (
                <Col xs={24} sm={24} md={8} lg={8}>
                  <Row gutter={[0, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <div
                        className="video-img"
                        onClick={() => {
                          //jk(item.id);
                          jk(BASE_URL + "uploads/video/" + item.video_name);
                        }}
                      >
                        <div className="playbtn-0n">
                          <CaretRightOutlined />
                        </div>
                        <div style={{ PointerEvent: "none" }}>
                          <Player startTime={2}>
                            <source
                              src={
                                BASE_URL + "uploads/video/" + item.video_name
                              }
                            />
                          </Player>
                          {/* <button
                          onClick={() => {
                            //jk(item.id);
                            jk(BASE_URL + "uploads/video/" + item.video_name);
                          }}
                          className="border-0 m-0"
                        >
                          <img
                            src="https://video-react.js.org/assets/poster.png"
                            alt="img"
                          />
                        </button> */}
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <div className="video-para">
                        <h3>{item.name}</h3>
                        <p>
                          <ShowMoreText
                            lines={3}
                            more="Show more"
                            less="Show less"
                            expanded={false}
                            truncatedEndingComponent={"..."}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></div>
                          </ShowMoreText>
                        </p>
                        <span className="time-vo">
                          {moment.tz(item.created_at, TIMEZONE).format("ll")}

                          <i className="fa fa-clock-o" aria-hidden="true"></i>
                          {moment.tz(item.created_at, TIMEZONE).format("LT")}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}

              <Players
                id={ModalVideo}
                open={modalVisible}
                toggleModal={onOpenModal}
                url={ModalVideo}
              />
            </Row>
          )}

          {videoLoadMoreURL != null ? (
            <Col xs={24} sm={24} md={24} lg={24}>
              <div
                className="Loadmore-btn-lb"
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <div className="loadmore-lm">
                  <Button
                    onClick={() => dispatch(loadMoreVideo(videoLoadMoreURL))}
                  >
                    <ReloadOutlined /> Load More
                  </Button>
                </div>
              </div>
            </Col>
          ) : null}
          {/*  {get_News && get_News.next_page_url != null ? (
        <Pagination
          pageSize={
            get_News && get_News.per_page != null ? get_News.per_page : null
          }
          size="small"
          total={get_News && get_News.total != null ? get_News.total : null}
          showTotal={(total) => `Total ${total} items`}
          current={currentNews}
          onChange={onChangeLogicNews}
        />
      ) : null} */}
        </>
      )}
    </div>
  );
};
export default Video;
