import { LEADS_DATA, LEADS_TASK } from "../../config/actionTypes";
import { CHIME_LEADS } from "../../config/constants";

const INITIAL_STATE = {
  load: false,
  leads: [],
  total: 0,
  leadTaskList: [],
};

const Leads = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LEADS_DATA:
      return {
        ...state,
        leads: action.payload,
        total: action.total
      };
    case LEADS_TASK:
      return {
        ...state,
        leadTaskList: action.payload
      };
    default:
      return state;
  }
};

export default Leads;