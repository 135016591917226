import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Tabs, Skeleton, Pagination, Spin } from "antd";
import { TIMEZONE } from "../../../config/actionTypes";
import moment from "moment-timezone";
import ShowMoreText from "react-show-more-text";
import {
  getNews,
  loadMoreNewsData,
} from "../../../redux/actions/dashboardAction";

const News = () => {
  const dispatch = useDispatch();
  const dashboard_data = useSelector((state) => state.dashboard_data);
  const { get_News, loading, News_data } = dashboard_data;

  useEffect(() => {
    collectNews();
  }, []);

  const collectNews = async () => {
    await dispatch(getNews());
  };

  const [currentNews, setCurrentNews] = useState(1);
  let setSizeNews = false;

  const sizeChangeLogic = (currentNews, size) => {
    setSizeNews = true;
    setCurrentNews(1);
  };

  const onChangeLogicNews = async (currentNews, size) => {
    !setSizeNews && setCurrentNews(currentNews);

    await dispatch(loadMoreNewsData(currentNews));
  };

  return (
    <div className="news-letter">
      {loading ? (
        <Spin className="loader-ld" />
      ) : (
        <>
          {News_data == "" ? (
            "No Data Found"
          ) : (
            <Row gutter={[16, 8]}>
              {News_data.map((item) => (
                <>
                  <Col xs={24} sm={24} md={3} lg={3}>
                    <div className="nld-date">
                      <h3>
                        {moment.tz(item.created_at, TIMEZONE).format("MMM")}
                      </h3>
                      <p>{moment.tz(item.created_at, TIMEZONE).format("D")}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={21} lg={21}>
                    <div className="nld-heading">
                      <h2>{item.title}</h2>
                      <p className="date-link">
                        <i className="fa fa-clock-o" aria-hidden="true"></i>
                        {moment.tz(item.created_at, TIMEZONE).format("LT")}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="post-news">
                      <p>
                        <ShowMoreText
                          lines={3}
                          more="Show more"
                          less="Show less"
                          expanded={false}
                          truncatedEndingComponent={"..."}

                          //onClick={executeScroll}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.body,
                            }}
                          ></div>
                        </ShowMoreText>
                        {/* {
                          <div
                            dangerouslySetInnerHTML={{ __html: item.body }}
                          />
                        } */}
                      </p>
                    </div>
                  </Col>
                </>
              ))}
            </Row>
          )}
          {get_News && get_News.next_page_url != null ? (
            <Pagination
              pageSize={
                get_News && get_News.per_page != null ? get_News.per_page : null
              }
              size="small"
              total={get_News && get_News.total != null ? get_News.total : null}
              showTotal={(total) => `Total ${total} items`}
              current={currentNews}
              onChange={onChangeLogicNews}
            />
          ) : null}
        </>
      )}
    </div>
  );
};
export default News;
